import React, {Component} from "react";
import {sha256} from "js-sha256";
import Axios from "axios";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {urlAxios} from '../Components/GetURL';
import {Backdrop} from "@mui/material";

class SharedInscriptionComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            showBrowserView:true,
            displayLoading:false
        };

    }
    _onRouteChangedKageSecur

    componentDidMount() {
        this.resizePage()
        window.addEventListener('resize', this.resizePage)
    }

    inscription(e){
        e.preventDefault()
        if((this.state.champsPassword.trim()).match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!.@#$%^&*?-])[A-Za-z\d!.@#$%^&*?-]{12,}$/)) {
            if(this.state.champsEmail.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)){
                if((this.state.champsIdentifiant.trim()).match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{4,29}$/)){
                    if(this.state.champsPasswordBis.trim() === this.state.champsPassword.trim()){
                        this.setState({displayLoading:true})
                        let hash = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsPassword.trim())) //Hashage avec protocole sha256
                        Axios.post(urlAxios + "inscriptionSK", {
                            empreinte: hash,
                            pseudo: this.state.champsIdentifiant.trim(),
                            clefToEncrypt: this.state.champsPassword.trim(),
                            email:this.state.champsEmail ? this.state.champsEmail.trim() : undefined,
                            code:this.props.code ? this.props.code : undefined,
                            appRedirect:"4AAudit"
                        }).then(response => {
                            if (response.data.resultat === 'done') {
                                setTimeout(() => {
                                    this.setState({displayLoading:false})
                                    Swal.fire({
                                        title: 'Succès!',
                                        text: "Votre compte a été créé !",
                                        icon:"success",
                                        confirmButtonText: 'Ok'
                                    }).then(()=>{
                                        global.emitter.emit("emitInscrisSK", {
                                            identifiant: this.state.champsIdentifiant,
                                            password: this.state.champsPassword
                                        })
                                    })
                                }, 1000)
                            } else {
                                this.setState({forceDisable: false,displayLoading:false})
                                Swal.fire({
                                    title: 'Erreur !',
                                    text: response.data.resultat,
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                })
                            }
                        }).catch(error => {
                            console.log(error);
                        });
                    }else{
                        this.setState({forceDisable:false})
                        Swal.fire({
                            title: 'Erreur !',
                            text: "La vérification de votre clef unique n'est pas correcte.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                }else{
                    this.setState({forceDisable:false})
                    Swal.fire({
                        title: 'Erreur !',
                        text: "Votre identifiant ne peut que contenir des : lettres a-z, nombres 0-9, tirets bas (_), et des points. Il doit contenir entre 4 et 29 caractères.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }else{
                this.setState({forceDisable:false})
                Swal.fire({
                    title: 'Erreur !',
                    text: "Votre email n'est pas dans un format correct.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }else{
            this.setState({forceDisable:false})
            let error;
            if (this.state.champsPassword.trim().includes(" ")){
                error = "Aucun espaces autorisé."
            }else if (!/[!@#\$%\^&\*\?-]/.test(this.state.champsPassword.trim())){
                error = "Au moins un symbole est requis."
            }else if (!/\d/.test(this.state.champsPassword.trim())){
                error = "Au moins un nombre est requis."
            }else if (!/[A-Z]/.test(this.state.champsPassword.trim())){
                error = "Au moins une majuscule est requise."
            }else if (!/[a-z]/.test(this.state.champsPassword.trim())){
                error = "Au moins une minuscule est requise."
            }else if (this.state.champsPassword.trim().length < 12){
                error = "Votre clef unique doit contenir minimum 12 caractères."
            }
            Swal.fire({
                title: 'Erreur !',
                text: "Votre clef unique ne respecte pas les critères de sécurité. " + error,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    render() { //Affichage sur la page
        return (
            <form onSubmit={(e)=>{
                e.preventDefault()
                this.inscription(e)
            }} className={"flexCenter"} style={{width:this.state.showBrowserView ? "75%":"95%", flexDirection:"column", alignItems:"flex-start"}}>
                <Backdrop
                    sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 99999999 }}
                    open={this.state.displayLoading}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <div className={"loaderTextKS SKFont"}>Chargement</div>
                        <h2 className={"SKFont"} style={{color:"#FFF"}}>Veuillez patienter quelques secondes</h2>
                    </div>
                </Backdrop>
                <div className={"flexCenter"} style={{width:"100%", flexDirection:!this.state.showBrowserView && "column", justifyContent:"space-between"}}>
                    <div style={{position:"relative", width:!this.state.showBrowserView?"95%":"46%", marginBottom:!this.state.showBrowserView&&15}}>
                        <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#FFF"}}>
                            <h3 className={"SKFont"} style={{color:"#000", margin:"0 5px 0 5px"}}>Identifiant</h3>
                        </div>
                        <input autoComplete={"off"} name={"champsIdentifiant"} required={true} className={"champsTexteSKInscription SKFont"} value={this.state.champsIdentifiant} onChange={(e)=>{this.handleChange(e)}} style={{width:"100%",border:"1px solid #000000"}} type={"text"} placeholder={"Identifiant"}/>
                    </div>
                    <div style={{position:"relative", width:!this.state.showBrowserView?"95%":"46%"}}>
                        <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#FFF"}}>
                            <h3 className={"SKFont"} style={{color:"#000", margin:"0 5px 0 5px"}}>Email</h3>
                        </div>
                        <input autoComplete={"off"} name={"champsEmail"} required={true} className={"champsTexteSKInscription SKFont"} value={this.state.champsEmail} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #000000", width:"100%"}} type={"email"} placeholder={"Email"}/>
                    </div>
                </div>
                <div className={"flexCenter"} style={{flexWrap:"wrap", color:"#000", width:"100%", justifyContent:"flex-start", alignItems:"flex-start", marginLeft:10}}>
                    <p className={"SKFont"} style={{margin:5, padding:0,fontSize:16, fontWeight:"bold", width:"100%"}}>Règles de sécurité - Votre clé unique doit contenir au moins : </p>
                    <p className={"SKFont"} style={{margin:5, padding:0, fontSize:14}}>- 12 caractères</p>
                    <p className={"SKFont"} style={{margin:5, padding:0, fontSize:14}}>- Un caractère majuscule et minuscule</p>
                    <p className={"SKFont"} style={{margin:5, padding:0, fontSize:14}}>- Un nombre</p>
                    <p className={"SKFont"} style={{margin:5, padding:0, fontSize:14}}>- Un symbole (!.@#$%^&*?-)</p>
                    <p className={"SKFont"} style={{margin:5, padding:0, fontSize:14, marginBottom:8}}>- Aucun espace</p>
                </div>
                <div className={"flexCenter"} style={{width:"100%", flexDirection:!this.state.showBrowserView && "column", justifyContent:"space-between",marginTop:10}}>
                    <div style={{position:"relative", width:!this.state.showBrowserView?"95%":"46%", marginBottom:!this.state.showBrowserView&&15}}>
                        <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#FFF"}}>
                            <h3 className={"SKFont"} style={{color:"#000", margin:"0 5px 0 5px"}}>Clef Unique</h3>
                        </div>
                        <input autoComplete={"off"} name={"champsPassword"}  required={true} className={"champsTexteSKInscription SKFont"} value={this.state.champsPassword} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #000000", width:"100%"}} type={this.state.showPassword ? "text":"password"} placeholder={"Clef Unique"}/>
                        <div style={{position:"absolute", width:"fit-content", right:10, top:14}}>
                            {this.state.showPassword ? (
                                <VisibilityIcon style={{cursor:"pointer", color:"#000"}} onClick={()=>{this.setState({showPassword:false})}}/>
                            ):(
                                <VisibilityOffIcon style={{cursor:"pointer", color:"#000"}} onClick={()=>{this.setState({showPassword:true})}}/>
                            )}
                        </div>
                    </div>
                    <div style={{position:"relative", width:!this.state.showBrowserView?"95%":"46%"}}>
                        <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#FFF"}}>
                            <h3 className={"SKFont"} style={{color:"#000", margin:"0 5px 0 5px", whiteSpace:"nowrap"}}>Clef Unique Verification</h3>
                        </div>
                        <input autoComplete={"off"} name={"champsPasswordBis"}  required={true} className={"champsTexteSKInscription SKFont"} value={this.state.champsPasswordBis} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #000000", width:"100%"}} type={this.state.showPassword2 ? "text":"password"} placeholder={"Clef Unique Verification"}/>
                        <div style={{position:"absolute", width:"fit-content", right:10, top:14}}>
                            {this.state.showPassword2 ? (
                                <VisibilityIcon style={{cursor:"pointer", color:"#000"}} onClick={()=>{this.setState({showPassword2:false})}}/>
                            ):(
                                <VisibilityOffIcon style={{cursor:"pointer", color:"#000"}} onClick={()=>{this.setState({showPassword2:true})}}/>
                            )}
                        </div>
                    </div>
                </div>
                <div className={"flexCenter"} style={{width:"100%", justifyContent:this.state.showBrowserView ? "flex-start":"center", marginTop:20}}>
                    <button disabled={this.state.displayLoading || !this.state.champsIdentifiant || !this.state.champsEmail || !this.state.champsPassword|| !this.state.champsPasswordBis} style={{width:"fit-content"}} type={"submit"} className={"buttonSK"}><h2 className={"SKFont"} style={{margin:0, fontWeight:600}}>S'inscrire {this.props.typeInvitation && "et accepter l'invitation"}</h2></button>
                </div>
            </form>
        );
    }


}
export default SharedInscriptionComponent;