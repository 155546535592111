import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {isMobile} from "react-device-detect";

import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import {AccountCircle, Close, Folder, Home, Menu} from "@mui/icons-material";
import {Dialog, DialogContent} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Axios from "axios";
import {sha256} from "js-sha256";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import {urlAxios} from '../../Components/GetURL';

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const allAcceptedPath = [
    "/sekuroom/accueil","/sekuroom/mesdossiers","/sekuroom/inscription","/sekuroom/espacedossier",
    "/sekuroom/invitationdossier","/sekuroom/mentionslegales"
]

class SharedHeaderSK extends Component {

    constructor(props) {
        super(props);
        this.state={
            openMenuConnexion:false,
            connected:false,
            displayConnexion:false,
            openDialogueModifEmail:false,
            openDialogueAjoutClient:false,
            champsIdentifiant:"",
            champsClef:"",
            champsNewEmail:"",
            displayed:false,
            openDialogueInvalidationCompte:false,
            displayMenuMobile:false,
            showBrowserView:true,
            freeClient:false,
            relance:false,
            readyToClick:true,
            sekurEmailClient:false,
            jwtDecoded:{},
            currentPath:""
        };
    }

    _onRouteChangedSK; _emitInscrisSK;
    _askConnectionSK;
    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentDidMount() {
        this.setState({
            jwtDecoded : sessionStorage.getItem("SK_jwt") ? jwt_decode(sessionStorage.getItem("SK_jwt")) : {},
            connected: !!sessionStorage.getItem("SK_jwt")
        })
        const queryParams = new URLSearchParams(window.location.search);
        let oc = queryParams.get("oc") //OpenConnexion = true ? ouvre dialog de connexion
        if(oc){
            this.setState({
                displayConnexion:true
            })
        }
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this._onRouteChangedSK = global.emitter.addListener("onRouteChangedSK",(data)=>{
            this.setState({
                jwt:data.jwt,
                jwtDecoded : (data.jwt ? jwt_decode(data.jwt):""),
                connected: !!data.jwt,
                displayConnexion:false,
                disableOutsideClick:false,
                displayMenuMobile:false
            })
        })

        this._askConnectionSK = global.emitter.addListener("askConnectionSK",(data)=>{
            this.setState({
                displayConnexion:true,
                displayMenuMobile:false,
                disableOutsideClick:data.disableOutsideClick
            })
        })

        this._emitInscrisSK = global.emitter.addListener("emitInscrisSK",(data)=>{
            this.setState({
                champsIdentifiant:data.identifiant,
                champsPassword:data.password
            },()=>{
                setTimeout(()=>{this.handleConnexion("fromInsc")},500)
            })
        })
        let currentPath = this.props.location.pathname.toLowerCase()
        this.setState({currentPath})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        let currentPath = this.props.location.pathname.toLowerCase()
        if(this.state.currentPath !== currentPath){
            this.setState({currentPath})
        }
    }
    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    componentWillUnmount(){
        if(this._onRouteChangedSK){this._onRouteChangedSK.remove()}
        window.removeEventListener('resize', this.resizePage)
    }

    disconnect(){
        this.setState({displayMenuMobile:false, readyToClick:true, connected:false, jwt:"", jwtDecoded:""})
        sessionStorage.removeItem("SK_jwt")
        Toast.fire({
            icon: 'success',
            title: 'Déconnecté.'
        })
        this.props.navigate("/4AAudit/Accueil")
    }

    handleConnexion(e){
        this.setState({showPassword:false, displayMenuMobile:false})
        if(e !== "fromInsc"){
            e.preventDefault()
        }
        let empreinte = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsPassword.trim()));
        Axios.post(urlAxios+"connexionSK",{
            empreinte:empreinte,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SK_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SK_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt,
                    jwtDecoded:jwt_decode(response.data.jwt)
                })
            }
            this.setState({displayConnexion:false},()=>{
                if (response.data.resultat === "Connecté"){
                    this.setState({
                        connected:true
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Bienvenue '+this.state.champsIdentifiant
                    })
                    this.setState({jwt:sessionStorage.getItem("SK_jwt")}, ()=>{
                        this.setState({
                            jwtDecoded : jwt_decode(this.state.jwt),
                            connected:true
                        })
                        global.emitter.emit("onRouteChangedSK", {
                            jwt: this.state.jwt,
                        })
                        global.emitter.emit("emitConnectedSK")
                        if((window.location.pathname).toLowerCase() === "/4aaudit/inscription" || (window.location.pathname).toLowerCase() === "/sekuroom/accueil" || (window.location.pathname).toLowerCase() === "/sekuroom/invitationdossier"){
                            this.props.navigate("/4AAudit/MesDossiers")
                        }
                    })
                }else{
                    /*check if dialog-catalog-SK opacity is 1*/
                    let target = ""
                    if(document.getElementById("dialog-catalog-SK")){
                        if(document.getElementById("dialog-catalog-SK").style.opacity === "1"){
                            target = "#dialog-catalog-SK"
                        }
                    }
                    Swal.fire({
                        title: 'Utilisateur introuvable.',
                        text: "Cette combinaison identifiant/clef unique n'existe pas.",
                        icon: 'error',
                        target,
                        confirmButtonText: 'Ok'
                    }).then(()=>{this.setState({displayConnexion:true,readyToClick:true})})
                }
            })
        }).catch(()=>{
            let target = ""
            if(document.getElementById("dialog-catalog-SK")){
                if(document.getElementById("dialog-catalog-SK").style.opacity === "1"){
                    target = "#dialog-catalog-SK"
                }
            }
            this.setState({displayConnexion:false},()=> {
                Swal.fire({
                    title: 'Erreur!',
                    text: "Serveur injoignable.",
                    icon: 'error',
                    target,
                    confirmButtonText: 'Ok'
                })
            })
        })
    }

    goToPage(clickedPage){
        this.setState({displayMenuMobile:false, displayConnexion:false},()=>{
            window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        })
    }

    render() {
        
        return(
            <>
                <div className={"navbar-frontpage-SK"}>
                    <Dialog
                        open={this.state.displayConnexion}
                        onClose={()=>{this.setState({displayConnexion:false,readyToClick:true})}}
                        disableBackdropClick={this.state.disableOutsideClick}
                        disableEscapeKeyDown={this.state.disableOutsideClick}
                        style={{zIndex:99999}}
                    >
                        <DialogContent className={"flexCenter"} style={{flexDirection:"column"}}>
                            <div className={"flexCenter"} style={{position:"relative", width:"100%", alignItems:"center"}}>
                                <div style={{position:"absolute", right:-5}}>
                                    <Close onClick={()=>{
                                        this.setState({readyToClick:true,displayConnexion: false})
                                        if(this.state.disableOutsideClick) {
                                            this.setState({disableOutsideClick:false})
                                            this.goToPage("/4AAudit/Accueil")
                                        }
                                    }} style={{width:35,height:35, cursor:"pointer"}}/>
                                </div>
                            </div>
                            <h1 className={"SKFont flexCenter"} style={{margin:"0 40px 10px 40px", fontWeight:"bold"}}>Connectez-vous à 4AAudit</h1>
                            <form style={{width:"100%"}} autoComplete="off" onSubmit={(e)=>{this.handleConnexion(e)}}>
                                <div style={{position:"relative", width:"100%"}}>
                                    <input autoComplete={"off"} name={"champsIdentifiant"} required className={"champsTexteSK SKFont"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF"}} type={"text"} placeholder={"Identifiant"}/>
                                </div>
                                <div style={{position:"relative", width:"100%"}}>
                                    <input autoComplete={"new-password"} name={"champsPassword"} required className={"champsTexteSK SKFont"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", position:"relative"}} type={this.state.showPassword ? "text":"password"} placeholder={"Clef unique"}/>
                                    <div style={{position:"absolute", width:"fit-content", right:10, top:24}}>
                                        {this.state.showPassword ? (
                                            <VisibilityIcon style={{cursor:"pointer"}} onClick={()=>{this.setState({showPassword:false})}}/>
                                        ):(
                                            <VisibilityOffIcon style={{cursor:"pointer"}} onClick={()=>{this.setState({showPassword:true})}}/>
                                        )}
                                    </div>
                                </div>
                                <div className={"flexCenter"}>
                                    <button type={"submit"} style={{width:"fit-content", marginTop:15, padding:10}} className={"buttonSK"}><h2 className={"SKFont"} style={{margin:0, fontWeight:600}}>Connexion</h2></button>
                                </div>
                            </form>
                        </DialogContent>
                    </Dialog>
                    {this.state.showBrowserView ? (
                        <>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <img onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/Accueil', "_blank")}}} onClick={()=>{this.props.navigate("/4AAudit/Accueil")}} style={{height:50, marginLeft:20, cursor:"pointer"}} src={require("../../assets/logo-4A-audit-ts.png")} alt={"LogoSekuroom"}/>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-around", alignItems:"center",width:"50%"}}>
                                <h2 onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/Accueil', "_blank")}}} onClick={()=>{this.goToPage("/4AAudit/Accueil")}} className={"textButtonSK flexCenter"} style={{color:"#000"}}>
                                    <Home style={{marginRight:10}}/>
                                    Accueil
                                </h2>
                                <h2 onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/MesDossiers', "_blank")}}} onClick={()=>{this.goToPage("/4AAudit/MesDossiers")}} className={"textButtonSK flexCenter"} style={{color:"#000"}}>
                                    <Folder style={{marginRight:10}}/>
                                    Mes dossiers
                                </h2>
                            </div>
                            <div onMouseEnter={()=>{this.setState({openMenuConnexion:true})}} style={{display:"flex", justifyContent:"center", alignItems:"center", position:"relative"}}>
                                <h2 className={"SKFont"} style={{color:"#000", marginRight:10}}>{this.state.jwtDecoded.pseudo}</h2>
                                <AccountCircle style={{width:40, height:"100%", color:"#000", marginRight:20, cursor:"pointer"}}/>
                                <div onMouseLeave={()=>{this.setState({openMenuConnexion:false})}} style={{transition:"all 0.26s ease", maxHeight:this.state.openMenuConnexion? 258:0, transformOrigin:"top", position:"absolute", backgroundColor:"transparent", paddingTop:55, top:0, right:20, width:250, borderRadius:10, overflow:"hidden"}}>
                                    <div className={"flexCenter"} style={{flexDirection:"column", backgroundColor:"#000", borderRadius:12, border:"1px solid black"}}>
                                        {this.state.connected === false ? (
                                            <>
                                                <div onClick={()=>{this.setState({displayConnexion:true, displayMenuMobile:false,openMenuConnexion:false})}} className={"hoverMenuSK"} style={{width:"100%", cursor:"pointer", borderRadius:"10px 10px 0px 0px"}}>
                                                    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Connexion</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                {/*<div onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/Inscription', "_blank")}}} onClick={()=>{this.goToPage("/4AAudit/Inscription")}} className={"hoverMenuSK"} style={{width:"100%", cursor:"pointer"}}>*/}
                                                {/*    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Inscription</h2>*/}
                                                {/*</div>*/}
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/MentionsLegales', "_blank")}}} onClick={()=>{this.goToPage("/4AAudit/MentionsLegales")}} className={"hoverMenuSK"} style={{width:"100%", cursor:"pointer", borderRadius:"0px 0px 10px 10px"}}>
                                                    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Mentions légales</h2>
                                                </div>

                                            </>
                                        ):(
                                            <>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/MentionsLegales', "_blank")}}} onClick={()=>{this.goToPage("/4AAudit/MentionsLegales")}} className={"hoverMenuSK"} style={{width:"100%", cursor:"pointer", borderRadius:"10px 10px 0px 0px"}}>
                                                    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Mentions légales</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onClick={()=>{this.disconnect()}} className={"hoverMenuSK flexCenter"} style={{width:"100%", cursor:"pointer", borderRadius:"0px 0px 10px 10px"}}>
                                                    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold", color:"#f44336",marginRight:15}}>Déconnexion</h2>
                                                    <ExitToAppIcon style={{color:'#f44336'}}/>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </>
                    ):(
                        <>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <img onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/Accueil', "_blank")}}} onClick={()=>{this.props.navigate("/4AAudit/Accueil")}} style={{height:50, marginLeft:20, cursor:"pointer"}} src={require("../../assets/logo-4A-audit-ts.png")} alt={"LogoSekuroom"}/>
                            </div>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", position:"relative"}}>
                                {this.state.displayMenuMobile ? (
                                    <Close onClick={()=>{this.setState({displayMenuMobile:false})}} style={{width:40,zIndex:9999, height:"100%", color:"#000", marginRight:20, cursor:"pointer"}}/>
                                ):(
                                    <Menu onClick={()=>{this.setState({displayMenuMobile:true})}} style={{width:40, height:"100%", color:"#000", marginRight:20, cursor:"pointer"}}/>
                                )}
                                <div style={{display:this.state.displayMenuMobile ? "block":"none", position:"absolute", backgroundColor:"transparent", paddingTop:55, top:0, right:0, width:"50vw", minWidth:250, height:"100%"}}>
                                    <div className={"flexCenter"} style={{flexDirection:"column", backgroundColor:"#000", borderRadius:17, boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
                                        <div onClick={()=>{this.goToPage("/4AAudit/Accueil")}} className={"hoverMenuSK flexCenter"} style={{width:"100%", borderRadius:"15px 15px 0px 0px", cursor:"pointer"}}>
                                            <Home style={{marginRight:10, width:30, height:30}}/>
                                            <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Accueil</h2>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                            <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                        </div>
                                        <div onClick={()=>{this.goToPage("/4AAudit/MesDossiers")}} className={"hoverMenuSK flexCenter"} style={{width:"100%", cursor:"pointer"}}>
                                            <Folder style={{marginRight:10, width:30, height:30}}/>
                                            <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Mes dossiers</h2>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                            <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                        </div>
                                        {this.state.connected === false ? (
                                            <>
                                                <div onClick={()=>{this.setState({displayConnexion:true, displayMenuMobile:false,openMenuConnexion:false})}} className={"hoverMenuSK"} style={{width:"100%",cursor:"pointer"}}>
                                                    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Connexion</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                {/*<div onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/Inscription', "_blank")}}} onClick={()=>{this.goToPage("/4AAudit/Inscription")}} className={"hoverMenuSK"} style={{width:"100%", cursor:"pointer"}}>*/}
                                                {/*    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Inscription</h2>*/}
                                                {/*</div>*/}
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/MentionsLegales', "_blank")}}} onClick={()=>{this.goToPage("/4AAudit/MentionsLegales")}} className={"hoverMenuSK"} style={{width:"100%", cursor:"pointer", borderRadius:" 0px 0px 15px 15px"}}>
                                                    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Mentions légales</h2>
                                                </div>
                                            </>
                                        ):(
                                            <>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/4AAudit/MentionsLegales', "_blank")}}} onClick={()=>{this.goToPage("/4AAudit/MentionsLegales")}} className={"hoverMenuSK"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold"}}>Mentions légales</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onClick={()=>{this.disconnect()}} className={"hoverMenuSK flexCenter"} style={{width:"100%", borderRadius:" 0px 0px 15px 15px", cursor:"pointer"}}>
                                                    <ExitToAppIcon style={{marginRight:10, width:30, height:30, color:'#f44336'}}/>
                                                    <h2 className={"SKFont"} style={{textAlign:"center", fontWeight:"bold", color:'#f44336'}}>Déconnexion</h2>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </>
                    )}


                </div>
            </>
        )
    }
}

export default withRouter(SharedHeaderSK)