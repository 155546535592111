import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';

import Swal from "sweetalert2";
import "./styleSK.css"
import {isMobile} from "react-device-detect";
import SharedFooterSK from "./components/SharedFooterSK";

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class MentionsLegalesSK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            eaaot:false
        };
    }

    componentWillUnmount(){

    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-SK flexCenter"}>
                    <div className={"flexCenter"} style={{height:"calc(100vh - 110px)", marginTop:70, width:"100%", flexDirection:"column", overflow:"hidden", justifyContent:"center"}}>
                        <h1 className={"SKFont"} style={{width:"95%", textAlign:"left"}}>Conditions générales d'utilisations : </h1>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"flex-start", width:"100%", height:"100%", overflow:"auto"}}>
                            <p className={"SKFont"} style={{color:"#000", maxWidth:this.state.showBrowserView ? "80%":"97%", fontSize:20}}>
                                CGU
                                <br/><br/>
                            PREAMBULE
                                <br/><br/>
                            La société SPURO SAS immatriculée au RCS de TROYES le 30/11/2021 sous le n°907 696 538 dont le siège social est 45, rue Pierre SENARD à Saint- Savine 10300 est une société spécialisée dans le développement informatique et les activités de sécurisation numérique, d’horodatage, d’archivage de Fichiers par utilisation de blockchain ou tout autre procédé.

                            Par son savoir-faire, elle a développé une solution informatique sur la base d’une technologie de type Blockchain visant à offrir des prestations sécurisées et notamment : horodatage, traçabilité, intégrité, antériorité,  de recherche et d’archivage numérique, etc.

                            Il est rappelé que la blockchain est une technologie de stockage et de transmissions d’informations permettant à ses utilisateurs de transmettre des informations sans intermédiaire, c’est-à-dire sans organe central de contrôle. L’identification de chaque partie s’effectue par un procédé cryptographique.

                            Cette solution informatique permet à des acteurs d’offrir un service d’archivage numérique permettant de bénéficier d’une traçabilité des prestations effectuées et Fichiers transmis par le biais de la blockchain pour le compte de leurs clients

                            Les Parties déclarent en conséquence, d’une part, qu’en respect des dispositions de l’article 1104 du Code civil, le présent contrat a été négocié et formé de bonne foi et, d’autre part, qu’en respect des dispositions de l’article 1112-1 du Code civil, elles ont dûment rempli leur devoir d’information.

                                <br/><br/>
                            DÉFINITIONS
                                <br/><br/>
                            Au sens du présent contrat, on entend par :

                            Abonné : l’étude ou le cabinet ou le Client ayant souscrit le contrat de prestation de services.

                            Actif numérique : bien immatériel auquel est attaché un droit de propriété et possédant une valeur déterminable.

                            Administrateur : responsable du système d’information SPURO.

                            « Bénéficiaires » : désigne toute personne morale, client de l’Abonné, , ayant donné le mandat écrit et signé à l’ Abonné d’agir en son nom et pour son compte pour la souscription et l’exécution des Services dans les conditions du présent Contrat.1012022 dont le siège est situé 45 rue Pierre SENARD 10300SAINT-SAVINE

                            Blockchain : Une blockchain, ou chaîne de blocs, est une technologie de stockage et de transmission d'informations sans organe de contrôle. La Blockchain faisant l’objet du Service offert dans les présentes est privée et hybride, c’est-à-dire consultable par des personnes externes à la Blockchain. La Blockchain pourra proposer aussi la gestion des Smart Contracts et des Actifs numériques.

                            Codes d’authentification : identifiant et clé unique déterminés par le Client.

                            Client : toute personne bénéficiant de la prestation de services faisant l’objet du contrat.

                            Code source : code écrit dans un langage de programmation et qui peut être converti pour constituer un programme exécutable.
                            Archivage numérique : service hautement sécurisé pour archiver, indexer et retrouver des fichiers numériques sensibles.

                            Compatibilité : la capacité d'un bien, d'un contenu numérique ou d'un service numérique à fonctionner avec du matériel informatique ou des logiciels, avec lesquels des biens, des contenus numériques ou des services numériques de même type sont normalement utilisés, sans qu'il soit nécessaire de convertir lesdits biens, matériels, logiciels, contenus numériques ou services numériques.
                            Données à caractère personnel : données à définies à l'article 4, point 1, du règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données.
                            Fichier(s) : éléments électroniques déposé(s) par un émetteur à destination de l’Abonné, disposant d’un certificat d’authenticité qui certifie l’authenticité des informations relatives à ce fichier, atteste que le fichier a été émis par un organisme reconnu et garantit qu’il n’a pas été modifié au cours de son archivage.

                            Empreinte : Agrégation de l’identifiant et de la clé permettant l’identification du client.

                            Fonctionnalité : capacité d'un bien, d'un contenu numérique ou d'un service numérique à remplir ses fonctions eu égard à sa finalité.

                            Gestionnaire : responsable du paramétrage fonctionnel de l’application.

                            Grille de tarifs : à la demande de l’Abonné, SPURO établit une grille de tarifs en fonction des services demandés.

                            Hachage : opération cryptographique aux fins d’assurer la sécurité des données en garantissant l’empreinte de celles-ci.

                            Hash : Opération utilisant un algorithme garantissant l’intégrité d’un fichier.

                            Horodatage : opération consistant à lier une date et une heure à un événement, à une information ou à une donnée informatique.

                            Internet : réseau mondial interconnecté par l’intermédiaire du protocole TCP/IP.

                            Interopérabilité : capacité d'un bien, d'un contenu numérique ou d'un service numérique à fonctionner avec du matériel informatique ou des logiciels différents de ceux avec lesquels des biens, des contenus numériques ou des services numériques de même type sont normalement utilisés.

                            Logiciel : programme d’ordinateur réalisé pour selon les spécifications du cahier des charges permettant l’accès aux services proposés sur la plateforme.

                            Masternode : serveur qui, en sus des fonctions d’un Node, procède à la sauvegarde des Fichiers enregistrés ; il y a trois Masternodes initiaux (KALIACT, GESICA, KAGESECUR) et le nombre des Masternodes est amené à évoluer.

                            Node (Nœud) : serveur relié au réseau blockchain et utilisant un programme relayant les transactions et conservant une copie du registre blockchain.

                            Plateforme : ensemble des pages et ressources disponibles sur le réseau Internet aux adresses des Nodes.

                            Service Numérique :   service permettant au consommateur de créer, de traiter ou de stocker des données sous forme numérique ou d'y accéder, ou un service permettant le partage ou toute autre interaction avec des données sous forme numérique qui sont téléversées ou créées par le consommateur ou d'autres utilisateurs de ce service ;

                            Smart Contract : contrat adossé à la Blockchain permettant, au moyen de protocoles informatiques, de vérifier les termes et conditions d’un contrat et d’exécuter automatiquement des obligations contractuelles.

                            Transaction : toute opération consistant à modifier l'état de la blockchain, et donc à ajouter de nouvelles données qu'elle stockera de façon irréversible.

                            Utilisateur : toute personne utilisant la Blockchain à toute fin.
                                <br/><br/>
                            IL A ETE CONVENU CE QUI SUIT :
                                <br/><br/>
                            Article 1. Fichiers contractuels
                                <br/><br/>

                            Le contrat qui régit les relations contractuelles entre les Parties est composé des Fichiers contractuels listés ci-dessous, à l’exclusion de tout autre Fichier :

                            - 	Les présentes conditions générales (applicables à tous les services) :
                            -	Le bon de commande (ci-après le « Bon de commande » ou « Formulaire d’adhésion ») ;
                            -	Les conditions particulières (spécifiques à certains services optionnels) ;
                            - La grille de tarifs disponible https://www.spuro.eu/files/Tarif-SPURO2022-V2.pdf
                            Il est expressément convenu que ces éléments sont visés ci-dessus par ordre hiérarchique décroissant, et que, en cas de contradiction entre des stipulations contenues dans ces Fichiers, ou en cas de nécessité d’interprétation de certaines d’entre elles, le Fichier de rang supérieur tiendra lieu de fondement.

                                <br/><br/>
                            Article 2. Relations contractuelles
                                <br/><br/>

                            2.1. Formation du contrat
                                <br/><br/>
                            Après avoir pris connaissance des Services proposés par SPURO, lesquels sont présentés en détail sur son site internet (www.spuro.eu), le futur Abonné fait une demande d’adhésion depuis l’espace « Inscription » du site de la société SPURO et fourni dans le cadre de cette demande, les informations préalables obligatoires demandées (telles que le n° SIRET, la raison sociale, les coordonnées, etc.), la sélection des Services souhaités.

                            La Société SPURO prendra contact avec le futur Abonné à la suite de cette suite à cette demande.

                            L’usage des services de la Blockchain suppose la détermination, par l’Abonné, d’un Code d’authentification, constitués par un identifiant et une clé unique, qui s’agrègent pour former une empreinte. L’ensemble fait l’objet d’un hachage pour assurer la sécurité des Identifiants. La clé unique demeure inconnue afin d’assurer la plus parfaite sécurité de l’accès au Service.

                            Lors de sa demande d’adhésion, le futur Abonné prend connaissance et accepte en cochant la case prévue à cet effet, les présentes Conditions d’Adhésion.

                            Chaque Abonné ne peut créer qu’un seul compte principal. Il garantit que les informations renseignées sont exactes, sincères et à jour. Les champs obligatoires sont assortis d’un astérisque. Tout défaut de renseignement aura pour conséquence la non-création de compte.

                            Une fois ces étapes passées avec succès, le futur Abonné est invité à éditer le Bon de commande dûment rempli (ou encore dénommé « Formulaire d’adhésion »).Toutes ces formalités se réalisent avec des applications opérationnelles dans la Blockchain.

                            Après examen de la demande d’adhésion, SPURO valide la demande en adressant à l’Abonné un mail de notification l’informant que sa demande d’adhésion a été acceptée et que son compte a été créé. L’Abonné est invité à se connecter à l’interface de connexion du Portail grâce à son adresse électronique et à sa clé unique, choisie lors de son adhésion au Portail.

                            L’Abonné s’enregistre et peut se connecter autant de fois qu’il le désire et avoir accès à ses informations et aux Fichiers qu’il a envoyés pendant la durée du contrat.

                            L’Abonné déclare être parfaitement informé des Services fournis par SPURO, objet des présentes, et reconnait que SPURO a été à son entière disposition pour l’informer sur les modalités du Service proposé par cette dernière.

                            Le Contrat est réputé formé dès la réception par l’Abonné du mail de de notification l’informant que sa demande d’adhésion a été acceptée adressé par SPURO.

                            Il est précisé que les présentes ne sont pas soumises aux dispositions du Code de la Consommation dès lors que les Services proposés par SPURO s’adressent uniquement à des Abonnés agissant à des fins professionnelles.

                            Il est par ailleurs dérogé aux dispositions des articles 1127-1 et 1127-2 du Code civil sur les contrats conclus par voie électronique.
                                <br/><br/>
                            2.2. Durée du contrat
                                <br/><br/>
                            Il est conclu pour une durée de douze (12) mois calendaires à compter de la date d’envoi du mail de de notification informant le Client que sa demande d’adhésion a été acceptée, visé à l’article 2.1 des présentes. Il est renouvelable par tacite reconduction, pour des périodes successives de douze (12) mois, sauf dénonciation par l’une ou l’autre des Parties moyennant le respect d’un préavis d’un (1) mois. Le préavis intervient par envoi à SPURO d’un courrier électronique avec demande d’avis de réception ; ce préavis est Fichieré dans la Blockchain qui lui donne date certaine.
                                <br/><br/>

                            Article 3. Objet du contrat
                                <br/><br/>

                            3.1. Présentation du service
                                <br/><br/>
                            La société SPURO propose diverses fonctionnalités accessibles par l’intermédiaire du service proposé et notamment un service de coffre-fort numérique permettant d’archiver en toutes sécurité des Fichiers informatiques. Ces fonctionnalités comprennent notamment les fonctions décrites ci-après.
                                <br/><br/>
                            3.1.1. Archivage de Fichiers
                                <br/><br/>
                            Tout Fichier enregistré dans un Node s’enregistre dans les trois Masternodes, par Nodes et par dossiers, à moins que l’Administrateur ou l’Abonné en ait décidé autrement en cochant la case réservée à cet effet.
                                <br/><br/>
                            3.1.2.	Fonctionnalités génériques
                                <br/><br/>
                            Le Service propose six fonctionnalités :

                            -	Chiffrement des Fichiers : tous les fichiers sauvegardés pesant moins de 100Mo sont chiffrés;

                            -	Sauvegarde des Fichiers : les Masternodes assurent la fonction de sauvegarde des Fichiers;

                            -	Gestion de la signature des Fichiers : les Fichiers peuvent être signés par tous les utilisateurs internes (gestionnaires, avocats Commissaires de justice) et externes (clients de l’Abonné, n’étant pas dans la Blockchain, utilisent celle-ci à des fins d’horodatage de leurs Fichiers). ;

                            -	Gestion des modes de facturation : la gestion est ouverte à l’Abonné étude de commissaire de justice, cabinet d’avocat, agence immobilière, entreprise, banque, assurance, etc. ;

                            -	Gestion des Fichiers : cette gestion est ouverte avec ou sans hébergement des Fichiers, tous les Fichiers hébergés étant chiffrés ;

                            -	Contact Client : le Service propose une zone de saisie des demandes des Clients et retours de réponses par SPURO.
                                <br/><br/>
                            3.1.3.	Fonctionnalités métiers
                                <br/><br/>
                            Le Service comprend les fonctionnalités suivantes :

                            -	Enregistrement de Fichiers : cette fonctionnalité permet de déposer tous types de Fichiers et de donner une valeur probatoire au dépôt ; elle est ouverte à tout type de Fichiers : constats, logos, photos, vidéo (2 Go), fichiers PDF, Fichiers divers, etc. ; elle permet la capture de pages Web ; elle fonctionne en .doc, .pdf, pdfimage, .gif, etc. Les Fichiers sont conservés cinq (5) ans. L’application permet l’attestation d’un Fichier mis dans la Blockchain (signature avec antériorité) avec ou sans validation d’un tiers ; elle permet aussi de connaître l’heure et l’empreinte d’un Fichier enregistré dans un Node (en utilisant l’option : « réalisation d’un certificat de dépôt ».

                            -	Réalisation d’un certificat : cette fonction s’effectue au moyen d’un tableau de bord ;

                            -	Fonctions diverses : les utilisateurs peuvent, à tout moment : consulter, télécharger, dupliquer ou transférer les Fichiers.

                            -	Mises en demeure : cette fonctionnalité est ouverte aux études des commissaires de justice du réseau Kaliact et des cabinets d’avocats du réseau Gesica. L’Abonné dépose ses factures et édite sa première mise en demeure sur la Blockchain ; en cas de non-paiement à une date fixée, il fait appel à une étude (qui doit être un Node) pour émettre à l’en-tête de celle-ci la mise en demeure, l’étude étant choisie sur liste déroulante. Le courrier doit être validé par l’étude du commissaire de justice territorialement compétent. L’Abonné est informé à chaque étape de la procédure. Une facture est émise une fois l’opération terminée.

                            -	Gestion interne : cette fonctionnalité permet un flux de Fichiers. Cabinets et études peuvent enregistrer un Fichier, l’envoyer à un autre cabinet ou une autre étude et le récupérer en retour. Tous les Fichiers sont enregistrés dans la Blockchain et traçables.

                            -	Validations simultanées : cette fonctionnalité permet de déposer un Fichier avec une signature double entre études, cabinets et Clients.
                                <br/><br/>
                            3.1.4.	Fonctionnalités de gestion
                                <br/><br/>
                            Le Service pose des interfaces Administrateurs, Gestionnaires et Utilisateurs.

                            Le Service comprend les fonctionnalités suivantes :
                            -	Interfaces Utilisateurs : quatre Interfaces sont mises à disposition : Interface du site SPURO, des gestionnaires,Clients, administrateurs..

                            Sont mises à disposition des Administrateurs les fonctionnalités suivantes :

                            -	StockViewer : Visualisation du stockage disponible des serveurs de la Blockchain ; arrêt ou redémarrage des serveurs pour les augmentations de volume ;

                            -	Liste utilisateur : analyse du registre pour tirer les informations des clients / gestionnaires / administrateurs (chiffrés) ; filtre des données (entreprises) dans un tableau (mais sans communication des clés uniques) ;

                            -	Traducteur : chiffrement d’un message en clair avec une clé AES (Advanced Encryption Standard) ; déchiffrement d’un message crypté avec une clef de contrôle format AES
                                <br/><br/>
                            3.2. Formation
                                <br/><br/>
                            Le Service comprend des séances de formation d’une (1) heure. Sont programmées deux (2) séances pour débutants, une séance de perfectionnement, puis une séance par mois pendant les six premiers mois.

                            L’Abonné reconnaît expressément qu’il est informé du fait que, sans formation, le Node est installé mais qu’il ne pourra pas l’utiliser. La responsabilité de la société SPURO ne peut en aucun cas être engagée en cas d’impossibilité ou de difficultés de fonctionnement du Service pour un Abonné ou un Client qui n’aurait pas suivi la formation.
                                <br/><br/>
                            3.3. Prestation de gestion des études et cabinets
                                <br/><br/>
                            La Blockchain constituant un registre partagé par ses Utilisateurs, ces derniers acceptent expressément de mettre à disposition les serveurs dont ils sont propriétaires pour les activités d’enregistrement des Fichiers dans la Blockchain.

                                <br/><br/>
                            Article 4. Service level agreement  (SLA)

                                <br/><br/>
                            4.1. Disponibilité du Service
                                <br/><br/>
                            La société SPURO s’engage à assurer une ouverture des Nodes 24 h. sur 24 et 7 jours sur 7.

                            La pluralité des Nodes garantit la totale disponibilité du Service, même en cas de panne d’un ou plusieurs d’entre eux, par l’utilisation d’un autre Node de la Blockchain.
                                <br/><br/>
                            4.2. Mises à jour
                                <br/><br/>
                            SPURO s’engage à assurer des mises à jour comme suit :

                            -	mise à jour des registres : intervention automatisée sur la base de dix (10) minutes ;

                            -	reprise des Fichiers archivés : mise à jour de vingt-quatre (24) à soixante-douze (72) heures en fonction des volumes de stockage.
                                <br/><br/>
                            4.3. Sécurité du Service
                                <br/><br/>
                            La société SPURO assure la sécurité des Fichiers, leur confidentialité et leur intégrité, notamment en proposant, par la Blockchain, une architecture décentralisée, des clés uniques et des méthodes cryptographiques.
                            La société SPURO propose une architecture de Blockchain répartie en une multiplication de Nodes, composantes de la Blockchain permettant les flux des Transactions ainsi que la sécurité de l’archivage des Fichiers.

                            Les normes "gestion de la sécurité" sont les suivantes :

                            - SRC01 : Gestion des hashs : chaque transaction se transforme en hash (empreinte) qui est enregistré dans le registre ;

                            - SRC02 : Cryptographie des informations : certaines informations sont chiffrées avec une clé symétrique Crypto (algorithme AES) ;

                            - SRC05 : Connexion de l’utilisateur : mode de récupération de l’identité et la clé unique (information sous pseudonyme)

                            - SRC08 : Intégrité des registres : les registres sont comparés avec une alerte à tous les administrateurs et une remise à jour immédiate ;

                            - SRC10 : Intégrité du Code source : contrôle permanent des Codes sources, applications et Smart Contracts ;

                            - SRC 12 : Gestion des mots de passe : double gestion des passes Administrateurs. Un mot de passe et défini en 12 positions : majuscules, minuscules, chiffres, et symboles spéciaux.

                            SPURO réalise en outre une gestion offline indépendante, Node par Node, ainsi que par les Masternodes.

                            Le Code source est crypté et l’original retiré du serveur ; il est mis à disposition du Client par clé USB à sa demande. La clé est envoyée par courrier.
                                <br/><br/>
                            4.4. Localisation
                                <br/><br/>
                            Les serveurs des Nodes et Masternodes sont tous localisés en France métropolitaine et les serveurs sont géographiquement séparés.

                                <br/><br/>
                            Article 5. Prérequis techniques

                                <br/><br/>
                            Pour une utilisation optimale des Services proposés par SPURO, une connexion au réseau Internet de bonne qualité et l’utilisation d’un Navigateur mis à jour sont requis.

                                <br/><br/>
                            Article 6. Dispositions financières
                                <br/><br/>

                            6.1. Prix de l’abonnement
                                <br/><br/>
                            Le tarif des abonnements SPURO est fixé dans la Grille de tarifs : https://www.spuro.eu/files/Tarif-SPURO2022-V2.pdf. La Grille des tarifs à la date de signature du contrat est valable sur toute la durée du contrat.

                            Une étude ou un cabinet facture, pour sa part, une prestation de service à SPURO pour la gestion locative du registre de la Blockchain SPURO et la gestion d’archivage des Fichiers de ses clients, ainsi qu’il est dit à l’article 3.3 des présentes. Dans l’offre « Node Kaliact/Gesica » présentée dans la Grille de tarifs, la somme correspondant à cette prestation est déduite de la facture établie par la société SPURO.

                            La société SPURO se réserve le droit de faire évoluer sa grille tarifaire. En cas de modification tarifaire, l’abonné aura la possibilité soit de conserver l’accès au service tel qu’il est prévu dans le bon de commande initial soit avec application des nouveaux tarifs, soit de solliciter la résiliation anticipée du contrat à la date prévue pour la modification tarifaire, sans que sa responsabilité ne puisse être engagée.
                                <br/><br/>
                            6.2. Facturation
                                <br/><br/>
                            La facturation du Service est annuelle, de date à date à compter de la date d’entrée en vigueur du contrat prévue à l’article 2.2 des présentes. Le paiement s’effectue par virement, prélèvement automatique ou chèque.
                            Le paiement doit être parvenu à SPURO sous trente (30) jours suivant la date d’émission de la facture.
                                <br/><br/>
                            6.3. Défaut de paiement
                                <br/><br/>
                            En cas de défaut de paiement d’un Abonné dans le délai prévu par l’article 6.2 des présentes, et après envoi par SPURO d’un courrier électronique avec demande d’avis de réception fichée dans la Blockchain qui lui donne date certaine, resté sans réponse dans un délai de trente (30) jours, SPURO se réserve la possibilité de fermeture du Node de l’Abonné.

                                <br/><br/>
                            Article 7. Garanties et responsabilités

                                <br/><br/>
                            7.1. Pour SPURO
                                <br/><br/>
                            7.1.1. Qualité de service
                                <br/><br/>
                            La société SPURO s’engage à mettre en œuvre toutes les contraintes opérationnelles et techniques nécessaires afin de tenir les engagements souscrits dans l’offre de Service, et notamment de disponibilité, performance, compatibilité, évolution.
                                <br/><br/>
                            7.1.2. Utilisation de logiciels
                            L’ensemble de logiciels utilisés dans la gestion du Service sont conçus et développés exclusivement par SPURO.
                                <br/><br/>
                            7.1.3.	Limites de responsabilité
                            La société SPURO ne peut être tenue responsable en cas :
                            • de difficultés d'accès au Service dû à des perturbations du réseau Internet ;
                            • d'utilisation du Service non expressément autorisée par le présent contrat ou non prévue dans la Fichier destinée à l’Utilisateur ;
                            • d’utilisation du Service dans un environnement ou selon une configuration ne respectant pas les prérequis techniques visés à l’article 5 des présentes ;
                            • de survenance de dommages qui résulteraient d'une faute ou d'une négligence du Client, ou que celui-ci aurait pu éviter en faisant appel aux conseils de la société SPURO ;
                            • d'accès frauduleux, illicite ou contraire aux dispositions du présent contrat, 	ainsi que d’utilisation inappropriée du Service par le Client ;
                            La société SPURO ne peut être tenue pour responsable des préjudices indirects subis par l’Abonné qui pourraient survenir du fait ou à l'occasion de l'exécution du présent contrat. Par dommages indirects, on entend notamment les pertes de gains ou de profits, perte de chance, dommages commerciaux tels qu’atteinte à l’image de marque, les conséquences de plaintes ou réclamations de tiers contre l’Abonné.
                                <br/><br/>
                            7.2	Pour l’Abonné
                                <br/><br/>
                            7.2.1. Codes d’authentification
                            Les Codes d’authentification visés à l’article 2.1 des présentes sont personnels et confidentiels. Ils ne peuvent être utilisés que pour permettre l'accès au Service et ne peuvent être communiqués à des tiers. L’Abonné est seul et totalement responsable de l'utilisation et de la confidentialité des Codes d’authentification et devra s'assurer que seules les personnes autorisées ont accès au Service. Il devra informer sans délai SPURO s'il constate une faille de sécurité liée notamment à la communication volontaire ou au détournement des Codes d’authentification, afin que SPURO puisse prendre sans délai toute mesure adaptée en vue de faire remédier à la faille de sécurité.
                            En cas de perte ou de détournement d'un des Codes d’authentification, un identifiant peut être récupéré, mais il est expressément rappelé que, en toute hypothèse, la clé unique demeure inconnue de SPURO .pour des raisons de sécurité des Fichiers et de confidentialité des Données.
                                <br/><br/>
                            7.2.2. Fichiers déposés sur la Blockchain
                                <br/><br/>
                            Pour des raisons de confidentialité et de sécurité, la société SPURO n’a pas accès aux Fichiers déposés sur la Blockchain et n’exerce aucune surveillance de leur contenu.
                            Il incombe à l’Abonné d’obtenir toutes les autorisations légales, réglementaires ou administratives nécessaires à l’activité développée grâce à la Blockchain. Il garantit notamment le respect plein et entier des dispositions relatives :

                            •	à la propriété intellectuelle (droit de la propriété littéraire et artistique, droit de la propriété industrielle et commerciale) et notamment qu’il dispose bien des autorisations et licences nécessaires son activité ;

                            •	au traitement des Données personnelles conformément aux dispositions visées par l’article 9 des présentes ;

                            •	au commerce électronique et notamment la présence sur son Site toutes les mentions légales obligatoires, et, le cas échéant, l’obligation d’affichage des conditions générales de vente sur son site Internet ;

                            •	à la licéité des contenus des Fichiers conformément aux dispositions pénales en vigueur ;

                            •	à la protection de la vie privée, de l’image et de la dignité des personnes ;

                            •	aux règles déontologiques s’appliquant le cas échéant aux professionnels faisant usage de la Blockchain.
                                <br/><br/>
                            Article 8. Droits de propriété incorporelle
                                <br/><br/>

                            Le Code source est la propriété de la société SPURO ; il est accessible par le biais de la Blockchain

                            La société SPURO est titulaire des droits de propriété industrielle et commerciale sur la marque , déposée à l’INPI le  sous le n°4800001.elle a fait l’objet d’une cession par M.COUTANT à la société SPURO, titulaire initial. Toute utilisation de la marque, sans autorisation expresse de la société, est interdite.

                            La société SPURO dispose du droit d’usage du nom de domaine :

                            La société SPURO est titulaire des droits d’exploitation sur le logo , lesquels ont fait l’objet d’une cession expresse le 30 novembre 2021 par la société HOME SWEET COM  , agence de création graphique et publicitaire qui l’a créée. Toute reproduction ou  représentation, totale ou partielle du logo, sans autorisation expresse de la société, est interdite.

                                <br/><br/>
                            Article 9. Protection des Données à caractère personnel
                                <br/><br/>

                            Dans le cadre de leurs relations contractuelles, les Parties s’engagent à respecter la règlementation en vigueur applicable au traitement de Données à caractère personnel et, notamment le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (dit « RGPD ») et la loi n° 78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés (dite « Loi Informatique et libertés »).

                            Les dispositions relatives à la protection des Données à caractère personnel sont publiées dans la Politique générale de confidentialité de la Blockchain, disponible à l’adresse suivante : https://www.spuro.eu/contenu/politique-de-confidentialite.

                                <br/><br/>
                            Article 10. Résiliation
                                <br/><br/>

                            En cas de non-respect de l’une quelconque des stipulations des présentes par l’une des Parties, et dix (10) jours après l’envoi à la partie défaillante d’une mise en demeure d’avoir à exécuter ses obligations par courrier électronique avec demande d’avis de réception fiché dans la Blockchain et resté sans effet, la présente convention sera résolue de plein droit aux torts de la partie défaillante, à l’initiative de la partie lésée, sans préjudice de dommages et intérêts éventuels.

                                <br/><br/>
                            Article 11. Force majeure
                                <br/><br/>

                            L’une des Parties ne pourra être considérée comme défaillante dans l’exécution des obligations découlant des présentes si cette défaillance est due à un cas de force majeure. Tout événement en dehors du contrôle de l'une ou l'autre partie et contre lequel elle n'a pu raisonnablement se prémunir constitue un cas de force majeure et suspend ce titre les obligations des Parties.

                            Toutefois, dans le cas où la suspension se poursuivrait au-delà d'un délai de trois mois, chacune des Parties se réserve la possibilité de résilier sans indemnité le présent contrat un mois après l'envoi d’un courrier électronique avec demande d’avis de réception fiché dans la Blockchain notifiant cette décision.

                            Les Parties admettent, sans que cette liste soit limitative, que relèvent notamment, soit de la force majeure, soit du cas fortuit, soit du fait d'un tiers, les dommages trouvant leurs origines ou leurs causes dans :

                            - une grève totale ou partielle (EDF, du personnel de l’une des Parties) ;
                            - un blocage des moyens de transport ou d’approvisionnement ;
                            - un arrêt de fourniture d'énergie (telle que l'électricité) ;
                            - des intempéries, sinistres et catastrophes naturelles ;
                            - une défaillance des réseaux de communication ;
                            - une guerre civile ou étrangère ;
                            - des émeutes ou mouvements populaire ;
                            - des attentats ;
                            - les pertes de connectivité dues aux opérateurs publics et privés dont dépendent les Parties ;
                            - des cyberattaques ;
                            - les conséquences d’une crise sanitaire.

                                <br/><br/>
                            Article 12. Non sollicitation de personnel
                                <br/><br/>

                            Les Parties au présent contrat renoncent pendant la durée des présentes, et pendant une durée de deux (2) ans après la cessation des relations contractuelles, à engager ou faire travailler directement ou par personne interposée tout collaborateur de l'autre Partie, quelle que soit sa spécialisation, et qui pourrait avoir des conséquences directes ou indirectes sur le territoire français.

                            Dans le cas où l'une des Parties ne respecterait pas cette convention, elle s'engage à dédommager l'autre partie (notamment des dépenses de recrutement, des frais de formation, des dommages résultant de sa réputation personnelle ou des engagements pris pour son compte), en lui versant immédiatement une somme forfaitaire égale aux appointements bruts que le collaborateur aura reçus pendant les douze mois précédant son départ.

                            Des aménagements à cette convention pourront être apportés dans le cas où une des Parties et elle seule donnera son accord exprès à l’autre pour l’embauche d’un de ses collaborateurs nommément désignés.

                                <br/><br/>
                            Article 13. Sous-traitance - Cession
                                <br/><br/>

                            13.1. Sous-traitance
                                <br/><br/>
                            La Société SPURO garantit que les opérateurs qui sont ses propres prestataires sont en mesure de tenir les engagements souscrits dans l’offre de Service faisant l’objet des présentes, et notamment la société OVH SAS dont le siège est 2,,rue de Kellermann à ROUBAIX,,59100,(https://www.ovh.com/fr/support/mentions-legales/) qui intervient en qualité d’hébergeur.
                                <br/><br/>
                            13.2. Cession
                                <br/><br/>
                            Le présent contrat pourra faire l’objet par la société SPURO d’une cession totale ou partielle, ou d’une transmission totale ou partielle à titre onéreux ou gracieux. En cas de cession d’un capital majoritaire ils peuvent procéder à la résiliation anticipée du contrat, sans faute et sans que leur responsabilité puisse être engagée.

                            La société SPURO s’engage à notifier à l’Abonné l’événement visé à l’alinéa précédent dès sa survenance par courrier électronique avec demande d’avis de réception fiché dans la Blockchain. L’Abonné qui ne souhaite pas poursuivre les relations contractuelles notifie sa décision à la société SPURO par courrier électronique avec demande d’avis de réception fiché dans la Blockchain dans un délai de15 (quinze) jours à compter de la date d’envoi de la notification.

                                <br/><br/>
                            Article 14. Référencement
                                <br/><br/>

                            L’Abonné accepte d’être référencé en tant que client de la Société SPURO sur le site spuro.eu.

                                <br/><br/>
                            Article 15. Assurance
                                <br/><br/>

                            La Société SPURO garantit avoir souscrit une formule d’assurance en responsabilité civile couvrant l’ensemble des activités suivantes :

                            •	Infrastructures / Réseaux / Hébergement / Cloud :
                            - Installation, configuration, paramétrage de matériel informatique.

                            •	Logiciels / Progiciels / Applications spécifiques :
                            - Edition de logiciels / progiciels ;
                            - Intégration de logiciels / progiciels ;
                            - Développement, programmation de logiciels / progiciels ;
                            - Test / recettes de logiciels / progiciels.

                            •	Services et conseils en informatique :
                            - Conseil, études, audit, AMOA (dont gestion de projet) ;
                            - Formation ;
                            - Autres services (recrutement, veille, conseil en stratégie, organisation, marketing, etc.) dans la limite de 30 % du chiffre d’affaires déclaré.

                                <br/><br/>
                            Article 16. Intégralité du contrat
                                <br/><br/>

                            Si une ou plusieurs stipulations du présent contrat sont tenues pour non valides ou déclarées telles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations conserveront leur pleine validité sauf si elles présentent un caractère indissociable avec la disposition non valide.
                                <br/><br/>
                            16.1. Non renonciation
                                <br/><br/>
                            Le fait pour l’Utilisateur et/ou l’Abonné et/ou SPURO de ne pas se prévaloir d’un manquement par la partie défaillante à l’une quelconque des obligations résultant du présent contrat ne saurait être interprété comme une renonciation à l’obligation en cause.
                                <br/><br/>
                            16.2. Nullité partielle
                                <br/><br/>
                            La nullité d’une clause quelconque des présentes n’affecte pas la validité des autres clauses ; elle se poursuit en l’absence du dispositif annulé sauf si la clause annulée rend la poursuite des relations contractuelles impossible ou déséquilibrée par rapport aux conventions initiales.
                                <br/><br/>
                            16.3. Notification
                                <br/><br/>
                            Toute notification requise aux termes des présentes devra être faite à l’autre Partie par courrier électronique avec demande d’avis de réception fiché dans la Blockchain.
                                <br/><br/>
                            16.4. Réclamation
                                <br/><br/>
                            Toute réclamation doit être adressée à la société SPURO par courrier électronique avec demande d’avis de réception fiché dans la Blockchain.

                                <br/><br/>
                            Article 17. Réversibilité
                                <br/><br/>

                            À tout moment en cours d'exécution du présent contrat, ainsi qu'en cas d'expiration ou de résiliation de tout ou partie du contrat pour quelque motif que ce soit, la société SPURO s'engage à assurer les opérations qui permettront à l’Abonné de récupérer les Fichiers..
                            Cette récupération des Fichiers s’effectue sur le Site. L’Abonné doit se rendre sur la page « Tableau de bord », sélectionner les Fichiers qu’il veut récupérer et sélectionner la fonctionnalité de téléchargement.
                            En cas de rupture de contrat, l’Abonné doit y procéder dans un délai de trois semaines après ladite rupture.

                                <br/><br/>
                            Article 18.  Droit applicable et Litiges
                                <br/><br/>

                            En cas de difficulté ou de différend entre les Parties à l'occasion de l'interprétation, de l'exécution ou de la terminaison du présent accord, les Parties conviennent de rechercher une solution amiable dans l'esprit de cet accord.

                            À défaut de règlement amiable, en cas de litige relatif à l’interprétation, la formation ou l’exécution du présent contrat, les Parties donnent compétence expresse et exclusive aux tribunaux compétents du ressort de Paris, nonobstant pluralité de défendeurs ou d’action en référé ou d’appel en garantie ou de mesure conservatoire.

                            En cas de litige relatif à l’interprétation, la formation, la validité ou l’exécution des présentes, les Parties reconnaissent de manière expresse que seule la loi française est applicable.
                                <br/><br/>
                            45 rue Pierre SEMARD           10300 St Savine         Tél. :  07 89 07 73 21
                                <br/><br/>
                            N°   Siret  907 696 538 00010 RCS Troyes          APE  6209Z
                            </p>
                        </div>
                    </div>
                </div>
                <SharedFooterSK/>
            </>
        );
    }
}

export default withRouter(MentionsLegalesSK)